




























































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
	BTooltip
} from "bootstrap-vue";
import { Parametre } from "@/api/models/parametres/parametre";
import { TypesParametreEnum } from "@/api/models/enums/typesParametreEnum";
import EditParametre from "./Edit.vue";
import CreateParametre from "./Create.vue";
import PreviewParametre from "./Details.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
	components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
		BTooltip,
		EditParametre,
		CreateParametre,
		PreviewParametre
	}
})
export default class Parametres extends Vue {
  perPage = 200;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  searchQuery = "";
  isSortDirDesc = false;
  sortBy = "nom";
  isCreateSidebarActive = false;
	isEditSidebarActive = false;
	isPreviewSidebarActive = false;
	selectedId = "";

	typesParametre = TypesParametreEnum

  tableColumns = [
    { key: "nom", sortable: false },
    { key: "type", sortable: false },
    { key: "description", sortable: false },
    { key: "actions", sortable: false },
  ];
  paginatedListParametres = new PaginatedList<Parametre>();

	async created() {
    await this.loadListParametres({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery
    });
  }

	get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListParametres.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListParametres.items.length
    );
  }

	@Watch("currentPage")
  @Watch("perPage")
  @Watch("searchQuery")
  async paginationChanged() {
    await this.loadListParametres({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery
    });
  }

	async loadListParametres(params: any) {
    if (!params || params?.reset) {
      this.paginatedListParametres = new PaginatedList<Parametre>();
    }

    await this.$http.myressif.parametres
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Parametre>) => {
				console.log(response)
        this.paginatedListParametres = response;
      });
  }

	async askDeleteParametre(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce paramètre ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.parametres.delete(id).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un paramètre",
          text: "Suppression effectuée avec succès",
        });
        await this.loadListParametres(null);
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

	openEditSidebar(id: string) {
		this.selectedId = id;
		this.isEditSidebarActive = true;
	}

	openPreviewSidebar(id: string) {
		this.selectedId = id;
		this.isPreviewSidebarActive = true;
	}
}
