






















































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BFormTextarea,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BInputGroupAppend,
  BInputGroup
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Parametre, ParametreUpdateModel } from "@/api/models/parametres/parametre";
import { TypesParametreEnum } from "@/api/models/enums/typesParametreEnum";
import { PerimetreEnum } from "@/api/models/enums/perimetresEnum";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormTextarea,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BInputGroupAppend,
    BInputGroup
  },
  name: "update-parametre",
})
export default class EditParametre extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateParametreSidebarActive!: boolean;
  @Prop({ required: true }) parametreId!: string;

  loading = false;
  required = required;
  refreshIndex = 0;
  optionsType = TypesParametreEnum
  optionsPerimetre = PerimetreEnum

  parametre: ParametreUpdateModel = {};
  valeurs: any = []
  newValeur = ""

  async created() {
    this.loading = true
    await this.$http.myressif.parametres
      .getById(this.parametreId)
      .then((res: Parametre) => {
        this.parametre = res;
        this.loading = false;
        switch (this.optionsType[this.parametre.type! as keyof typeof TypesParametreEnum]) {
          case TypesParametreEnum.stringlist:
            this.valeurs = Object.values(JSON.parse(this.parametre.valeur!))
            break;
        }
      });
  }

  getEnumKeyByEnumValue(myEnum: any, enumValue: any) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  closeSidebar() {
    this.parametre = {}
    this.$emit("update:is-update-parametre-sidebar-active", false);
  }
  
  addValeur() {
    this.valeurs.push(this.newValeur);
    this.newValeur = "";
    this.refreshIndex++;
  }

  removeValeur(index: number) {
    this.valeurs.splice(index, 1);
    this.refreshIndex++;
  }

  async submit() {
    this.loading = true;

    if (this.parametre.type == 'stringlist')
      this.parametre.valeur = JSON.stringify(this.valeurs);
      
    await this.$http.myressif.parametres
      .update(
        this.parametreId,
        this.parametre
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du paramètre",
            text: "Paramètre modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-parametre");
        this.$emit("update:is-update-parametre-sidebar-active", false);
        this.loading = false;
      });
  }
}
